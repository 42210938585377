import React from 'react';
import { Card } from 'react-bootstrap';

import { IContact } from '../../types/types';
import { textDate } from '../../utils/someFunctions';

import './contactItem.sass';

interface ContactItemProps {
    contact: IContact;
    onClick: (contact: IContact) => void;
};


const ContactItem: React.FC<ContactItemProps> = ({contact, onClick}) => {
    return (
        <Card 
            className="contact-card"
            onClick={() => onClick(contact)}
            style={{border: contact.result ? '3px solid rgb(25, 135, 84)' : contact.reaction ? '2px solid #e85d04' : '3px solid rgb(220, 53, 69)'}}
        >
            <div className="contact-card__date">
                <div>{textDate(contact.date)}</div>
                <div>{contact.index + 1}</div>
            </div>
            <div className="contact-card__text">{contact.place}</div>
            <div className="contact-card__text">{contact.girl}</div>
            <div className="contact-card__phrase">{contact.firstPhrase}</div>
        </Card>        
    );
};

export default ContactItem;