import React, {useState, useContext} from 'react';
import {observer} from 'mobx-react-lite';
import { Button, Modal } from 'react-bootstrap';

import { INote } from '../../types/types';
import { deleteNote } from '../../http/notesAPI';
import { Context } from '../..';
import { textDate } from '../../utils/someFunctions';
import ModalNoteUpdate from './ModalNoteUpdate';

interface ModalNoteDetailProps {
    show: boolean;
    onHide: () => void;
    note: INote;
};


const ModalNoteDetail: React.FunctionComponent<ModalNoteDetailProps> = observer(({show, onHide, note}) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [hover, setHover] = useState<boolean>(false);
    const {user} = useContext(Context);

    const removeNote = () => {
        if (window.confirm('Ты действительно хочешь удалить контакт?')) {
            deleteNote(note.id);
            user.setToggle(!user.toggle);
            onHide();
        }        
    };


    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            centered
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            >
            <Modal.Body style={{border: '4px solid grey', borderRadius: '20px'}}>
                <div className="note-card__date">{textDate(note.createdAt)}</div>
                {Boolean(note.theme) && <div className="note-card__theme">{note.theme}</div>}
                {Boolean(Array.isArray(note.items)) && Boolean(note.items.length) &&
                    <ul className='note-card__list'>
                        {Boolean(note.items.length) && note.items.map(item =>
                            <li key={item.id} className='note-card__list_item'>{item.text}</li>
                        )}
                    </ul>
                }
                <div className="note-card_footer" >
                    <Button variant={"outline-secondary"} className="note__card_close" onClick={onHide}>Закрыть</Button>
                    <div 
                        className="note-card_icons"
                        style={{display: hover ? 'block' : 'none'}}
                    >
                        <i className="bi bi-pencil-fill list-item__icon" onClick={() => setVisible(true)}></i>
                        <i className="bi bi-trash3-fill list-item__icon" onClick={removeNote}></i>
                    </div>
                </div>
            </Modal.Body>
            <ModalNoteUpdate 
                show={visible} 
                onHide={() => setVisible(false)} 
                note={note}
            />
        </Modal>
    );
});

export default ModalNoteDetail;