import React, { useState, useEffect } from 'react';
import {Modal, Button} from 'react-bootstrap';

import CUNote from '../../components/CreateUpdate/CUNote';
import { updateNote } from '../../http/notesAPI';
import { INote, INoteItem } from '../../types/types';

interface ModalNoteUpdateProps {
    show: boolean;
    onHide: () => void;
    note: INote;
};


const ModalNoteUpdate: React.FC<ModalNoteUpdateProps> = ({show, onHide, note}) => {
    const [theme, setTheme] = useState<string | undefined>('');
    const [items, setItems] = useState<INoteItem[]>([]);

    useEffect(() => {
        setTheme(note.theme);
        setItems(note.items);
    }, [show]);
    
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            centered
            >
            <Modal.Body>
                <CUNote 
                    id={note.id}
                    theme={theme}
                    items={items}
                    setTheme={setTheme}
                    setItems={setItems}
                    handler={updateNote}
                    title='Обновить заметку'
                    btnName='Обновить'
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"outline-secondary "} onClick={onHide}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalNoteUpdate;