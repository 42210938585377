import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Spinner, Card, Button, Container } from 'react-bootstrap';
import {Helmet} from "react-helmet";

import { IContact } from '../../types/types';
import { MAIN_ROUTE } from '../../utils/consts';
import { fetchOneContact, deleteContact } from '../../http/contactsAPI';
import ModalContactUpdate from '../../components/Modals/ModalContactUpdate';

import './contactPage.sass';


const ContactPage: React.FunctionComponent = () => {
    const [contact, setContact] = useState<IContact>({} as IContact);
    const [loading, setLoading] = useState<boolean>(true);
    const [visible, setVisible] = useState<boolean>(false);
    const {id} = useParams<{id: string}>();
    const navigate = useNavigate();

    useEffect(() => {
        fetchOneContact(Number(id))
            .then(data => setContact(data))
            .catch(err => alert(err.message))
            .finally(() => setLoading(false));
    }, []);

    const removeContact = () => {
        if (window.confirm('Ты действительно хочешь удалить контакт?')) {
            deleteContact(contact.id);
            navigate(MAIN_ROUTE);
        }        
    };

    if (loading) {
        return <Spinner animation={"border"}/>
    }

    return (
        <Container className="contact">
            <Helmet>
                <title>{`Контакт ${contact.id}`}</title>
                <meta name="description" content={`Контакт ${contact.id}`} />
            </Helmet>

            <Card 
                className="contact__card"
                style={{border: contact.result ? '3px solid rgb(25, 135, 84)' : contact.reaction ? '2px solid #e85d04' : '3px solid rgb(220, 53, 69)'}}
                >
                <div className="contact__card_date">
                    <div>{new Date(contact.date).toUTCString().substring(5, 17)}</div>
                    {/* <div id="num">{contact.id}</div> */}
                </div>
                <div className="contact__card_text"><span>Место:</span> {contact.place}</div>
                <div className="contact__card_text"><span>Девушка:</span> {contact.girl}</div>
                <div className="contact__card_text"><span>Первая фраза:</span> {contact.firstPhrase}</div>
                <div className="contact__card_text"><span>Реакция девушки:</span> {contact.reaction ? "Положительная" : "Отрицательная"}</div>
                {Boolean(contact.proposal) && <div className="contact__card_text"><span>Призыв:</span> {contact.proposal}</div>}
                <div className="contact__card_text"><span>Результат:</span> {contact.result ? "Успех" : "Отказ"}</div>
                {Boolean(contact.payload) && <div className="contact__card_text"><span>Добыча:</span> {contact.payload}</div>}

                {Boolean(Array.isArray(contact.contact_wwgs)) && Boolean(contact.contact_wwgs.length) && <div className="contact__card_text"><span>Что было сделано хорошо:</span>
                    <ol className='contact__card_list'>
                        {Boolean(contact.contact_wwgs) && contact.contact_wwgs.map(item =>
                            <li key={item.id}>{item.text}</li>
                        )}
                    </ol>
                </div>}
                {Boolean(Array.isArray(contact.contact_whis)) && Boolean(contact.contact_whis.length) && <div className="contact__card_text"><span>Над чем следует поработать:</span>
                    <ol className='contact__card_list'>
                        {Boolean(contact.contact_whis) && contact.contact_whis.map(item =>
                            <li key={item.id}>{item.text}</li>
                        )}
                    </ol>
                </div>}
                
                <div className="contact__card_icons">
                    <i className="bi bi-pencil-fill list-item__icon" onClick={() => setVisible(true)}></i>
                    <i className="bi bi-trash3-fill list-item__icon" onClick={removeContact}></i>
                </div>
            </Card>
            <Button variant={"outline-secondary"} className='contact__btn' onClick={() => navigate('windows.history.back')} >Назад</Button>
            
            <ModalContactUpdate 
                show={visible} 
                onHide={() => setVisible(false)} 
                contact={contact}
            />
        </Container>
    );
};

export default ContactPage;