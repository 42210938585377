// @ts-ignore
export const sort = (items) => {        
    let sortItems = [];

    sortItems = [...items].sort((a, b) => {
        return a.createdAt < b.createdAt ? 1 : -1;
    });

    return sortItems;
};

export const selectFile = (e: React.ChangeEvent<HTMLInputElement>) => { 
    const files: FileList | null = e.target.files;
    if (files) {
        if (files[0].size > 1048576) {
            return alert('Изображение должно быть менее 1мб')
        }
        // @ts-ignore 
        const fileExtension: string = files[0].name.split(".").at(-1);
        const allowedFileTypes: string[] = ["jpg", "jpeg", "png", "webp"];
        if (!allowedFileTypes.includes(fileExtension)) {
            return alert(`Расширение файла не поддерживается. Допустымые расширения: ${allowedFileTypes.join(", ")}`);
        }

        return files[0];
    } else {
        return null;
    }
};

export const textDate = (date: string): string => {
    const str = new Date(date).toUTCString().substring(5, 17);
    let ruStr: string;

    ruStr = str.replace(/Jan/g, 'января').replace(/Feb/g, 'февраля').replace(/Mar/g, 'марта').replace(/Apr/g, 'апреля').replace(/May/g, 'мая').replace(/Jun/g, 'июня').replace(/Jul/g, 'июля').replace(/Aug/g, 'августа').replace(/Sep/g, 'сентября').replace(/Oct/g, 'октября').replace(/Nov/g, 'ноября').replace(/Dec/g, 'декабря');

    return ruStr;
};