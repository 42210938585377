import { $authHost } from ".";

export const createContact = async (contact: FormData) => {
    const {data} = await $authHost.post('api/contacts', contact);
    return data;
};

// export const createContact = async (date: string, place: string, girl: string, firstPhrase: string, reaction: boolean, proposal: string | undefined, result: boolean, payload: string | undefined, whatWasGood: IWhat[], whatHadImprove: IWhat[]) => {
//     const {data} = await $authHost.post('api/contacts', {date, place, girl, firstPhrase, reaction, proposal, result, payload, whatWasGood, whatHadImprove});
//     return data;
// };

export const fetchContacts = async () => {
    const {data} = await $authHost.get('api/contacts');
    return data;
};

export const fetchOneContact = async (id: number) => {
    const {data} = await $authHost.get('api/contacts/' + id);
    return data;
};

export const updateContact = async (id: number, contact: FormData) => {
    const {data} = await $authHost.put('api/contacts/' + id, contact);
    return data;
};

// export const updateContact = async (id: number, date: string, place: string, girl: string, firstPhrase: string, reaction: boolean, proposal: string | undefined, result: boolean, payload: string | undefined, whatWasGood: string | undefined, whatHadImprove: string | undefined) => {
//     const {data} = await $authHost.put('api/contacts/' + id, {date, place, girl, firstPhrase, reaction, proposal, result, payload, whatWasGood, whatHadImprove});
//     return data;
// };

export const deleteContact = async (id: number) => {
    const {data} = await $authHost.delete('api/contacts/' + id);
    return data;
};