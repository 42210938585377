import React, {useState, useEffect, useContext} from 'react';
import { Container, Spinner } from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import {Helmet} from "react-helmet";

import List from '../../components/List/List';
import NoteItem from '../../components/NoteItem/NoteItem';
import ModalNoteDetail from '../../components/Modals/ModalNoteDetail';
import Pageup from '../../components/Pageup/Pageup';
import { INote } from '../../types/types';
import { sort } from '../../utils/someFunctions';
import { fetchNotes } from '../../http/notesAPI';
import { Context } from '../..';

import './notesPage.sass';


const NotesPage: React.FC = observer(() => {
    const [notes, setNotes] = useState<INote[]>([]);
    const [note, setNote] = useState<INote>({} as INote);
    const [loading, setLoading] = useState<boolean>(true);
    const [visible, setVisible] = useState<boolean>(false);
    const {user} = useContext(Context);

    useEffect(() => {
        fetchNotes()
            .then(data => setNotes(sort(data)))
            .catch(err => alert(err.message))
            .finally(() => setLoading(false));
    }, [user.toggle]);

    const selectNote = (item: INote) => {
        setNote(item);
        setVisible(true)
    };

    return (        
        <Container className='main-page'>
            <Helmet>
                <title>Заметки</title>
                <meta name="description" content="Список заметок" />
            </Helmet>

            {loading ? 
                <Spinner animation={"border"} variant="light"/>
                :
                <List
                    items={notes}
                    renderItem={(note: INote) => 
                        <NoteItem
                            note={note}
                            onClick={(note) => selectNote(note)}
                            key={note.id}
                        />
                    } 
                />
            }
            <Pageup />
            <ModalNoteDetail 
                show={visible} 
                onHide={() => setVisible(false)} 
                note={note}
            />
        </Container>
    );
});

export default NotesPage;