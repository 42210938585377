export const MAIN_ROUTE: string = '/';
export const LOGIN_ROUTE: string = '/login';
export const REGISTER_ROUTE: string = '/register';
export const NOTES_ROUTE: string = '/notes';
// export const NOTE_ROUTE: string = '/note';
export const ADD_NOTE_ROUTE: string = '/note';
export const CONTACT_ROUTE: string = '/contact';
export const ADD_CONTACT_ROUTE: string = '/contact';
export const CALENDAR_ROUTE: string = '/calendar';
export const NOTFOUND_ROUTE: string = '/404';
