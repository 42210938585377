import React from 'react';
import { useNavigate } from 'react-router-dom';
import {Container, Button, Form, Dropdown, Row, Col} from 'react-bootstrap';

import { MAIN_ROUTE, CONTACT_ROUTE } from '../../utils/consts';
import { IWhat } from '../../types/types';

interface CUContactProps {
    id: number;
    date: string;
    place: string;
    girl: string;
    firstPhrase: string;
    reaction: boolean;
    proposal?: string;
    result: boolean;
    payload?: string;
    whatWasGood: IWhat[];
    whatHadImprove: IWhat[];
    setDate: (date: string) => void;
    setPlace: (place: string) => void;
    setGirl: (girl: string) => void;
    setFirstPhrase: (firstPhrase: string) => void;
    setReaction: (reaction: boolean) => void;
    setProposal: (proposal: string) => void;
    setResult: (result: boolean) => void;
    setPayload: (payload: string) => void;
    setWhatWasGood: (whatWasGood: IWhat[]) => void;
    setWhatHadImprove: (whatHadImprove: IWhat[]) => void;
    // handler: (id: number, date: string, place: string, girl: string, firstPhrase: string, reaction: boolean, proposal: string | undefined, result: boolean, payload: string | undefined, whatWasGood: string | undefined, whatHadImprove: string | undefined) => Promise<unknown>;
    handler: (id: number, formData: FormData) => Promise<unknown>;
    title: string;
    btnName: string;
};


const CUContact: React.FC<CUContactProps> = ({id, date, place, girl, firstPhrase, reaction, proposal, result, payload, whatWasGood, whatHadImprove, setDate, setPlace, setGirl, setFirstPhrase, setReaction, setProposal, setResult, setPayload, setWhatWasGood, setWhatHadImprove, handler, title, btnName}) => {
    const navigate = useNavigate();

    const addWhatWasGood = () => {
        // @ts-ignore
        setWhatWasGood([...whatWasGood, {text: '', id: Date.now()}]);
    };

    const removeWhatWasGood = (id: number) => {
        // @ts-ignore
        setWhatWasGood(whatWasGood.filter(item => item.id !== id));
    };

    const changeWhatWasGood = (key: string, value: string, id: number) => {
        // @ts-ignore
        setWhatWasGood(whatWasGood.map(item => item.id === id ? {...item, [key]: value} : item));
    };

    const addWhatHadImprove = () => {
        // @ts-ignore
        setWhatHadImprove([...whatHadImprove, {text: '', id: Date.now()}]);
    };

    const removeWhatHadImprove = (id: number) => {
        // @ts-ignore
        setWhatHadImprove(whatHadImprove.filter(item => item.id !== id));
    };

    const changeWhatHadImprove = (key: string, value: string, id: number) => {
        // @ts-ignore
        setWhatHadImprove(whatHadImprove.map(item => item.id === id ? {...item, [key]: value} : item));
    };

    const onClick = () => {
        if (!date.trim() || !place.trim() || !girl.trim() || !firstPhrase.trim()) {
            return alert('Поля: Когда, Место, Девушка, Первая фраза - обязательны для заполнения');
        }

        const formData = new FormData();
        formData.append('date', date);
        formData.append('place', place);
        formData.append('girl', girl);
        formData.append('firstPhrase', firstPhrase);
        formData.append('reaction', String(reaction));
        formData.append('proposal', String(proposal));
        formData.append('result', String(result));
        formData.append('payload', String(payload));
        formData.append('whatWasGood', JSON.stringify(whatWasGood));
        formData.append('whatHadImprove', JSON.stringify(whatHadImprove));

        if (btnName === 'Добавить') {
            // @ts-ignore 
            // handler(date, place, girl, firstPhrase, reaction, proposal, result, payload, whatWasGood, whatHadImprove)
            handler(formData)
                .then(() => navigate(MAIN_ROUTE))
                .catch(err => alert(err.response.data.message));
        } else {
            // handler(id, date, place, girl, firstPhrase, reaction, proposal, result, payload, whatWasGood, whatHadImprove)
            handler(id, formData)
                .then(() => navigate(CONTACT_ROUTE + '/' + id))
                .catch(err => alert(err.response.data.message));
        }
    };

    return (
        <Container className="d-flex justify-content-center mt-5">
            <div>
                <h1 style={{marginBottom: 0}}>{title}</h1>
                <Form className='mt-0'>
                    <label htmlFor="date" className="mt-3">Когда</label>
                    <Form.Control
                        type="date"
                        value={date}
                        onChange={e => setDate(e.target.value)}
                    />
                    <label htmlFor="place" className="mt-2">Место</label> 
                    <Form.Control as="textarea"
                        value={place}
                        type="string"
                        onChange={e => setPlace(e.target.value)}
                        placeholder="Место"
                        maxLength={300}
                    />
                    <label htmlFor="girl" className="mt-2">Девушка</label> 
                    <Form.Control as="textarea"
                        value={girl}
                        type="string"
                        onChange={e => setGirl(e.target.value)}
                        placeholder="Девушка"
                        maxLength={300}
                    />
                    <label htmlFor="firstPhrase" className="mt-3">Первая фраза</label> 
                    <Form.Control as="textarea"
                        value={firstPhrase}
                        onChange={e => setFirstPhrase(e.target.value)}
                        placeholder="Первая фраза"
                        maxLength={300}
                    />
                    <Dropdown className="mt-3 mb-3">
                        <Dropdown.Toggle variant={"outline-secondary"}>{reaction ? 'Положительная' : 'Реакция девушки'}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item 
                                onClick={() => setReaction(true)}>
                                    Положительная
                            </Dropdown.Item>
                            <Dropdown.Item 
                                onClick={() => setReaction(false)}>
                                    Отрицательная
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <label htmlFor="proposal" className="mt-3">Призыв</label> 
                    <Form.Control as="textarea"
                        value={proposal}
                        onChange={e => setProposal(e.target.value)}
                        placeholder="Призыв"
                        maxLength={300}
                    />
                    <Dropdown className="mt-3 mb-3">
                        <Dropdown.Toggle variant={"outline-secondary"}>{result ? 'Успех' : 'Результат'}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item 
                                onClick={() => setResult(true)}>
                                    Успех
                            </Dropdown.Item>
                            <Dropdown.Item 
                                onClick={() => setResult(false)}>
                                    Отказ
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <label htmlFor="payload" className="mt-3">Добыча</label> 
                    <Form.Control as="textarea"
                        value={payload}
                        onChange={e => setPayload(e.target.value)}
                        placeholder="Добыча"
                        maxLength={300}
                    />
                    <label htmlFor="whatWasGood" className="mt-4">Что было сделано хорошо</label>
                    <Button 
                        id="whatWasGood"
                        className="ms-3"
                        variant="outline-secondary"
                        onClick={addWhatWasGood}
                        >Добавить пункт
                    </Button>
                    {Boolean(Array.isArray(whatWasGood)) && whatWasGood?.map(item =>
                        <Row className="mt-3" key={item.id} style={{display: 'block'}}>
                            <Col md={9}>
                                <Form.Control as="textarea"
                                    value={item.text}
                                    onChange={e => changeWhatWasGood('text', e.target.value, item.id)}
                                    placeholder="Что было хорошо"
                                    maxLength={500}
                                />
                            </Col>
                            <Col md={3}>
                                <Button 
                                    variant="outline-danger"
                                    className='mt-1 w-100'
                                    onClick={() => removeWhatWasGood(item.id)}
                                    >Удалить
                                </Button>
                            </Col>
                        </Row>    
                    )}
                    <br/>
                    <label htmlFor="whatHadImprove" className="mt-4">Над чем следует поработать</label>
                    <Button 
                        id="whatHadImprove"
                        className="ms-3"
                        variant="outline-secondary"
                        onClick={addWhatHadImprove}
                        >Добавить пункт
                    </Button>
                    {Boolean(Array.isArray(whatHadImprove)) && whatHadImprove?.map(item =>
                        <Row className="mt-3" key={item.id} style={{display: 'block'}}>
                            <Col md={9}>
                                <Form.Control as="textarea"
                                    value={item.text}
                                    onChange={e => changeWhatHadImprove('text', e.target.value, item.id)}
                                    placeholder="Над чем поработать"
                                    maxLength={500}
                                />
                            </Col>
                            <Col md={3}>
                                <Button 
                                    variant="outline-danger"
                                    className='mt-1 w-100'
                                    onClick={() => removeWhatHadImprove(item.id)}
                                    >Удалить
                                </Button>
                            </Col>
                        </Row>    
                    )}
                </Form>
                <Button variant={btnName === 'Добавить' ? "outline-success" : "outline-primary"} onClick={onClick} className="mt-3">{btnName}</Button>           
            </div>
        </Container>
    );
};

export default CUContact;