import React, { useState, useEffect } from 'react';
import {Modal, Button} from 'react-bootstrap';

import CUContact from '../CreateUpdate/CUContact';
import { updateContact } from '../../http/contactsAPI';
import { IContact, IWhat } from '../../types/types';

interface ModalContactUpdateProps {
    show: boolean;
    onHide: () => void;
    contact: IContact;
};


const ModalContactUpdate: React.FC<ModalContactUpdateProps> = ({show, onHide, contact}) => {
    const [date, setDate] = useState<string>('');
    const [place, setPlace] = useState<string>('');
    const [girl, setGirl] = useState<string>('');
    const [firstPhrase, setFirstPhrase] = useState<string>('');
    const [reaction, setReaction] = useState<boolean>(false);
    const [proposal, setProposal] = useState<string | undefined>('');
    const [result, setResult] = useState<boolean>(false);
    const [payload, setPayload] = useState<string | undefined>('');
    const [whatWasGood, setWhatWasGood] = useState<IWhat[]>([]);
    const [whatHadImprove, setWhatHadImprove] = useState<IWhat[]>([]);

    useEffect(() => {
        setDate(contact.date);
        setPlace(contact.place);
        setGirl(contact.girl);
        setFirstPhrase(contact.firstPhrase);
        setReaction(contact.reaction);
        setProposal(contact.proposal);
        setResult(contact.result);
        setPayload(contact.payload);
        setWhatWasGood(contact.contact_wwgs);
        setWhatHadImprove(contact.contact_whis);
    }, [show]);
    
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            centered
            >
            <Modal.Body>
                <CUContact 
                    id={contact.id}
                    date={date}
                    place={place}
                    girl={girl}
                    firstPhrase={firstPhrase}
                    reaction={reaction}
                    proposal={proposal}
                    result={result}
                    payload={payload}
                    whatWasGood={whatWasGood}
                    whatHadImprove={whatHadImprove}
                    setDate={setDate}
                    setPlace={setPlace}
                    setGirl={setGirl}
                    setFirstPhrase={setFirstPhrase}
                    setReaction={setReaction}
                    setProposal={setProposal}
                    setResult={setResult}
                    setPayload={setPayload}
                    setWhatWasGood={setWhatWasGood}
                    setWhatHadImprove={setWhatHadImprove}
                    handler={updateContact}
                    title='Обновить контакт'
                    btnName='Обновить'
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"outline-secondary "} onClick={onHide}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalContactUpdate;