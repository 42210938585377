import React, { useState } from 'react';
import {Helmet} from "react-helmet";

import CUNote from '../components/CreateUpdate/CUNote';
import { createNote } from '../http/notesAPI';
import { INoteItem } from '../types/types';


const AddNotePage: React.FC = () => {
    const [theme, setTheme] = useState<string>('');
    const [items, setItems] = useState<INoteItem[]>([]);
    
    return (
        <>
            <Helmet>
                <title>Добавить заметку</title>
                <meta name="description" content="Добавить заметку" />
            </Helmet>
            
            <CUNote 
                id={0}
                theme={theme}
                items={items}
                setTheme={setTheme}
                setItems={setItems}
                //@ts-ignore
                handler={createNote}
                title='Добавить заметку'
                btnName='Добавить'
            />
        </>
    );
};

export default AddNotePage;