import React, {useState, useEffect, useContext} from 'react';
import { Container, Spinner } from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import {Helmet} from "react-helmet";

import List from '../../components/List/List';
import ContactItem from '../../components/ContactItem/ContactItem';
import ModalContactDetail from '../../components/Modals/ModalContactDetail';
import Pageup from '../../components/Pageup/Pageup';
import { IContact } from '../../types/types';
import { sort } from '../../utils/someFunctions';
import { fetchContacts } from '../../http/contactsAPI';
import { Context } from '../..';

import './mainPage.sass';


const MainPage: React.FC = observer(() => {
    const [contacts, setContacts] = useState<IContact[]>([]);
    const [contact, setContact] = useState<IContact>({} as IContact);
    const [conts, setConts] = useState<number>(0);
    const [rejections, setRejections] = useState<number>(0);
    const [successes, setSuccesses] = useState<number>(0);
    const [neutral, setNeutral] = useState<number>(0);
    const [angry, setAngry] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [visible, setVisible] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>('');
    const [visibleItems, setVisibleItems] = useState<IContact[]>([]);
    const {user} = useContext(Context);

    useEffect(() => {
        fetchContacts()
            .then(data => setContacts(sort(data.map((item: IContact, i: number) => Object.assign(item, {index: i})))))
            .catch(err => alert(err.message))
            .finally(() => setLoading(false));
    }, [user.toggle]);

    useEffect(() => {
        setConts(contacts.length);
        setRejections(contacts.filter(item => !item.result).length);
        setSuccesses(contacts.filter(item => item.result).length);
        setNeutral(contacts.filter(item => !item.result && item.reaction).length);
        setAngry(contacts.filter(item => !item.result && !item.reaction).length);
    }, [contacts]);

    useEffect(() => {
        setVisibleItems(filterContacts(contacts, filter));
    }, [contacts, filter]);

    function filterContacts(items: IContact[], filter: string) {
        switch (filter) {
            case 'rejections':
                return items.filter(item => !item.result);
            case 'angry':
                return items.filter(item => !item.result && !item.reaction);
            case 'neutral':
                return items.filter(item => !item.result && item.reaction);
            case 'successes':
                return items.filter(item => item.result);
            case 'all':
                return items;
            default:
                return items;
        }
    };

    const selectContact = (item: IContact) => {
        setContact(item);
        setVisible(true)
    };

    return (        
        <Container className='main-page'>
            <Helmet>
                <title>Контакты</title>
                <meta name="description" content="Список контактов" />
            </Helmet>

            {loading ? 
                <Spinner animation={"border"} variant="light"/>
                :
                <>
                    <div className='main-page__stats' id="up">
                        <i 
                            className="bi bi-universal-access main-page__stats_icon"
                            onClick={(e) => setFilter('all')}
                        ></i> {conts}
                        <i 
                            className="bi bi-person-fill-x main-page__stats_icon" 
                            style={{color: 'rgb(220, 53, 69)'}}
                            onClick={(e) => setFilter('rejections')}
                        ></i> {rejections}
                        <i 
                            className="bi bi-emoji-angry main-page__stats_icon"
                            style={{color: 'rgb(220, 53, 69)'}}
                            onClick={(e) => setFilter('angry')}
                        ></i> {angry}
                        <i 
                            className="bi bi-emoji-neutral main-page__stats_icon" 
                            style={{color: '#e85d04'}}
                            onClick={(e) => setFilter('neutral')}
                        ></i> {neutral}
                        <i 
                            className="bi bi-emoji-heart-eyes main-page__stats_icon" 
                            style={{color: 'rgb(25, 135, 84)'}}
                            onClick={(e) => setFilter('successes')}
                        ></i> {successes}
                    </div>
                    <List
                        items={visibleItems}
                        renderItem={(contact: IContact) => 
                            <ContactItem
                                contact={contact}
                                onClick={(contact) => selectContact(contact)}
                                key={contact.id}
                            />
                        } 
                    />
                </>
            }
            <Pageup />
            <ModalContactDetail 
                show={visible} 
                onHide={() => setVisible(false)} 
                contact={contact}
            />
        </Container>
    );
});

export default MainPage;