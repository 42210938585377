import React from 'react';
import { Card } from 'react-bootstrap';

import { INote } from '../../types/types';
import { textDate } from '../../utils/someFunctions';

import './noteItem.sass';

interface NoteItemProps {
    note: INote;
    onClick: (note: INote) => void;
};


const NoteItem: React.FC<NoteItemProps> = ({note, onClick}) => {
    return (
        <Card 
            className="note-card"
            onClick={() => onClick(note)}
        >
            <div className="note-card__date">{textDate(note.createdAt)}</div>
            {Boolean(note.theme) && <div className="note-card__theme">{note.theme}</div>}
            {Boolean(Array.isArray(note.items)) && Boolean(note.items.length) &&
                <ul className='note-card__list'>
                    {Boolean(note.items.length) && note.items.filter((item, i) => i < 5).map(item =>
                        <li key={item.id} className='note-card__list_item'>{item.text}</li>
                    )}
                </ul>
            }
        </Card>        
    );
};

export default NoteItem;