import React, {useState, useContext} from 'react';
import {observer} from 'mobx-react-lite';
import { Button, Modal } from 'react-bootstrap';

import { IContact } from '../../types/types';
import { deleteContact } from '../../http/contactsAPI';
import { Context } from '../..';
import { textDate } from '../../utils/someFunctions';
import ModalContactUpdate from '../../components/Modals/ModalContactUpdate';

interface ModalContactDetailProps {
    show: boolean;
    onHide: () => void;
    contact: IContact;
};


const ModalContactDetail: React.FunctionComponent<ModalContactDetailProps> = observer(({show, onHide, contact}) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [hover, setHover] = useState<boolean>(false);
    const {user} = useContext(Context);

    const removeContact = () => {
        if (window.confirm('Ты действительно хочешь удалить заметку?')) {
            deleteContact(contact.id);
            user.setToggle(!user.toggle);
            onHide();
        }        
    };


    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            centered
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            >
            <Modal.Body 
                style={{border: contact.result ? '5px solid rgb(25, 135, 84)' : contact.reaction ? '3px solid #e85d04' : '5px solid rgb(220, 53, 69)', borderRadius: '20px'}}
                >
                <div className="contact__card_date">
                    <div>{textDate(contact.date)}</div>
                    <div id="num">{contact.index + 1}</div>
                </div>
                <div className="contact__card_text"><span>Место:</span> {contact.place}</div>
                <div className="contact__card_text"><span>Девушка:</span> {contact.girl}</div>
                <div className="contact__card_text"><span>Первая фраза:</span> {contact.firstPhrase}</div>
                <div className="contact__card_text"><span>Реакция девушки:</span> {contact.reaction ? "Положительная" : "Отрицательная"}</div>
                {Boolean(contact.proposal) && <div className="contact__card_text"><span>Призыв:</span> {contact.proposal}</div>}
                <div className="contact__card_text"><span>Результат:</span> {contact.result ? "Успех" : "Отказ"}</div>
                {Boolean(contact.payload) && <div className="contact__card_text"><span>Добыча:</span> {contact.payload}</div>}

                {Boolean(Array.isArray(contact.contact_wwgs)) && Boolean(contact.contact_wwgs.length) && <div className="contact__card_text"><span>Что было сделано хорошо:</span>
                    <ol className='contact__card_list'>
                        {Boolean(contact.contact_wwgs) && contact.contact_wwgs.map(item =>
                            <li key={item.id}>{item.text}</li>
                        )}
                    </ol>
                </div>}
                {Boolean(Array.isArray(contact.contact_whis)) && Boolean(contact.contact_whis.length) && <div className="contact__card_text"><span>Над чем следует поработать:</span>
                    <ol className='contact__card_list'>
                        {Boolean(contact.contact_whis) && contact.contact_whis.map(item =>
                            <li key={item.id}>{item.text}</li>
                        )}
                    </ol>
                </div>}
                
                <div className="contact__card_footer" >
                    <Button variant={"outline-secondary"} className="contact__card_close" onClick={onHide}>Закрыть</Button>
                    <div 
                        className="contact__card_icons" 
                        style={{display: hover ? 'block' : 'none'}}
                    >
                        <i className="bi bi-pencil-fill list-item__icon" onClick={() => setVisible(true)}></i>
                        <i className="bi bi-trash3-fill list-item__icon" onClick={removeContact}></i>
                    </div>
                </div>
            </Modal.Body>
            <ModalContactUpdate 
                show={visible} 
                onHide={() => setVisible(false)} 
                contact={contact}
            />
        </Modal>
    );
});

export default ModalContactDetail;