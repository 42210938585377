import React, { useState } from 'react';
import {Helmet} from "react-helmet";

import CUContact from '../components/CreateUpdate/CUContact';
import { createContact } from '../http/contactsAPI';
import { IWhat } from '../types/types';


const AddContactPage: React.FC = () => {
    const [date, setDate] = useState<string>('');
    const [place, setPlace] = useState<string>('');
    const [girl, setGirl] = useState<string>('');
    const [firstPhrase, setFirstPhrase] = useState<string>('');
    const [reaction, setReaction] = useState<boolean>(false);
    const [proposal, setProposal] = useState<string>('');
    const [result, setResult] = useState<boolean>(false);
    const [payload, setPayload] = useState<string>('');
    const [whatWasGood, setWhatWasGood] = useState<IWhat[]>([]);
    const [whatHadImprove, setWhatHadImprove] = useState<IWhat[]>([]);
    
    return (
        <>
            <Helmet>
                <title>Добавить контакт</title>
                <meta name="description" content="Добавить контакт" />
            </Helmet>
            
            <CUContact 
                id={0}
                date={date}
                place={place}
                girl={girl}
                firstPhrase={firstPhrase}
                reaction={reaction}
                proposal={proposal}
                result={result}
                payload={payload}
                whatWasGood={whatWasGood}
                whatHadImprove={whatHadImprove}
                setDate={setDate}
                setPlace={setPlace}
                setGirl={setGirl}
                setFirstPhrase={setFirstPhrase}
                setReaction={setReaction}
                setProposal={setProposal}
                setResult={setResult}
                setPayload={setPayload}
                setWhatWasGood={setWhatWasGood}
                setWhatHadImprove={setWhatHadImprove}
                //@ts-ignore
                handler={createContact}
                title='Добавить контакт'
                btnName='Добавить'
            />
        </>
    );
};

export default AddContactPage;