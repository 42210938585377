import {makeAutoObservable} from 'mobx';

export default class UserStore {
    _isAuth: boolean;
    _toggle: boolean;

    constructor() {
       this._isAuth = false;
       this._toggle = false;
       makeAutoObservable(this); 
    };

    setIsAuth(bool: boolean) {
        this._isAuth = bool;
    };

    setToggle(bool: boolean) {
        this._toggle = bool;
    };

    get isAuth() {
        return this._isAuth;
    };

    get toggle() {
        return this._toggle;
    };
};