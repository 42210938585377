import React from 'react';
import { useNavigate } from 'react-router-dom';
import {Container, Button, Form, Row, Col} from 'react-bootstrap';

import { NOTES_ROUTE, ADD_NOTE_ROUTE } from '../../utils/consts';
import { INoteItem } from '../../types/types';

interface CUNoteProps {
    id: number;
    theme?: string;
    items: INoteItem[];
    setTheme: (theme: string) => void;
    setItems: (items: INoteItem[]) => void;
    handler: (id: number, formData: FormData) => Promise<unknown>;
    title: string;
    btnName: string;
};


const CUNote: React.FC<CUNoteProps> = ({id, theme, items, setTheme, setItems, handler, title, btnName}) => {
    const navigate = useNavigate();

    const addItem= () => {
        // @ts-ignore
        setItems([...items, {text: '', id: Date.now()}]);
    };

    const removeItem = (id: number) => {
        // @ts-ignore
        setItems(items.filter(item => item.id !== id));
    };

    const changeItem = (key: string, value: string, id: number) => {
        // @ts-ignore
        setItems(items.map(item => item.id === id ? {...item, [key]: value} : item));
    };

    const onClick = () => {
        if (!items.length) {
            return alert('Заполни хотя бы 1 пункт');
        }

        const formData = new FormData();
        formData.append('theme', String(theme));
        formData.append('items', JSON.stringify(items));

        if (btnName === 'Добавить') {
            // @ts-ignore
            handler(formData)
                .then(() => navigate(NOTES_ROUTE))
                .catch(err => alert(err.response.data.message));
        } else {
            handler(id, formData)
                .then(() => navigate(ADD_NOTE_ROUTE))
                .catch(err => alert(err.response.data.message));
        }
    };

    return (
        <Container className="d-flex justify-content-center mt-5">
            <div>
                <h1 style={{marginBottom: 0}}>{title}</h1>
                <Form className='mt-0'>
                    <label htmlFor="theme" className="mt-3">Тема</label>
                    <Form.Control
                        type="theme"
                        value={theme}
                        placeholder='Тема'
                        onChange={e => setTheme(e.target.value)}
                    />
                    
                    {Boolean(Array.isArray(items)) && items?.map(item =>
                        <Row className="mt-3" key={item.id} style={{display: 'block'}}>
                            <Col md={9}>
                                <Form.Control as="textarea"
                                    value={item.text}
                                    onChange={e => changeItem('text', e.target.value, item.id)}
                                    placeholder="запись..."
                                    maxLength={1000}
                                />
                            </Col>
                            <Col md={3}>
                                <Button 
                                    variant="outline-danger"
                                    className='mt-1 w-100'
                                    onClick={() => removeItem(item.id)}
                                    >Удалить
                                </Button>
                            </Col>
                        </Row>    
                    )}
                    <Button 
                        id="items"
                        className="w-100"
                        variant="outline-secondary"
                        onClick={addItem}
                        style={{marginTop: '20px'}}
                        >Добавить
                    </Button>
                </Form>
                <Button variant={btnName === 'Добавить' ? "outline-success" : "outline-primary"} onClick={onClick} className="mt-3">{btnName}</Button>           
            </div>
        </Container>
    );
};

export default CUNote;