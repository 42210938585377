import { LOGIN_ROUTE, REGISTER_ROUTE, MAIN_ROUTE, NOTES_ROUTE, ADD_NOTE_ROUTE, CONTACT_ROUTE, ADD_CONTACT_ROUTE, CALENDAR_ROUTE, NOTFOUND_ROUTE } from "./utils/consts";
import {AuthPage, AddNotePage, AddContactPage, CalendarPage, MainPage, NotesPage, ContactPage, Page404} from './pages';

export const authRoutes = [
    {
        path: MAIN_ROUTE,
        Component: MainPage
    },
    {
        path: CONTACT_ROUTE + '/:id',
        Component: ContactPage
    },
    {
        path: ADD_CONTACT_ROUTE,
        Component: AddContactPage
    },
    {
        path: NOTES_ROUTE,
        Component: NotesPage
    },
    {
        path: ADD_NOTE_ROUTE,
        Component: AddNotePage
    },
    {
        path: CALENDAR_ROUTE,
        Component: CalendarPage
    },
    {
        path: NOTFOUND_ROUTE,
        Component: Page404
    },
];

export const publicRoutes = [
    {
        path: LOGIN_ROUTE,
        Component: AuthPage
    },
    {
        path: REGISTER_ROUTE,
        Component: AuthPage
    }
];